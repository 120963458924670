import simpleParallax from 'simple-parallax-js';
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';

document.addEventListener("DOMContentLoaded", function() {  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./target-blank.js');
  require('./sliders')
  require('./menu')
  require('./header')
  require('./resize')
  require('./map')
  require('./google-map')
  require('./forms')
});

// remove preloader
window.addEventListener('load', function () {
  $('.preloader').fadeOut();
  document.querySelector('html').classList.add('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 1000,
    delay: 200,
    // easing: 'linear',
    easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    // easing: 'ease-in-out-quart',
  });

  const images = document.querySelectorAll('.image-parallax');
  new simpleParallax(images, {
    scale: 1.1
  });

  var rellax = new Rellax('.rellax', {
    breakpoints:[576, 768, 992]
  });

  new NativejsSelect({
    selector: '.custom-select'
  });

  const customDPs = document.querySelectorAll('.custom-datepicker-wrapper')

  customDPs.forEach(item => {
    const dpInput = item.querySelector('.custom-datepicker')
    const icon = item.querySelector('.custom-datepicker-wrapper__icon')
    let dp = new AirDatepicker(dpInput, {
      locale: localeEn,
      minDate: new Date(),
      // position: 'bottom center'
    })
    icon.addEventListener('click', function () {
      dp.show()
    })
  })

});
