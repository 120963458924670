(function (){
    const map = document.getElementById('map')

    if (map) {
        const areas = map.querySelectorAll('#map .area-wrapper')
        const popup = document.querySelector('.map-popup')
        const popupContent = popup.querySelector('.map-popup__content')
        const areasContainer = map.querySelector('.interactive-areas')

        areas.forEach(area => {
            area.addEventListener('click', function (e) {
                areas.forEach(item => item.classList.remove('zoom'))
                const upperArea = document.querySelector('#map > .area-wrapper')
                if (upperArea) {
                    areasContainer.append(upperArea)
                }
                popupContent.innerHTML = ''
                map.append(this)
                setTimeout(function () {
                    this.classList.add('zoom')
                    const id = this.getAttribute('id')
                    const copy = popup.querySelector(`template[data-id=${id}]`).innerHTML
                    popupContent.innerHTML = copy
                    popup.classList.add('visible')
                }.bind(this), 3)
            })

            // area.addEventListener('mouseleave', function () {
            //     const id = this.querySelector('.area').getAttribute('id')
            //     this.classList.remove('zoom')
            //     popup.classList.remove('visible')
            //     popup.querySelector(`.map-popup__content[data-id=${id}]`).classList.remove('visible')
            // });
        })

        const close = popup.querySelector('.map-popup__close')
        close.addEventListener('click', function (e) {
            e.preventDefault();
            popup.classList.remove('visible')
            areas.forEach(item => item.classList.remove('zoom'))
            const upperArea = document.querySelector('#map > .area-wrapper')
            if (upperArea) {
                areasContainer.append(upperArea)
            }
        })
    }
})()