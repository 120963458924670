(function () {
    // const forms = document.querySelectorAll('.form')
    // const popupClass = 'submit-popup'
    // forms.forEach(form => {
    //     const formPopup = form.nextElementSibling
    //     const isPopup = formPopup.classList.contains(popupClass)
    //     form.addEventListener('submit', function (e) {
    //         e.preventDefault()
    //         form.classList.add('sending')
    //         if (isPopup) {
    //             formPopup.className = `${popupClass} visible sending`
    //             // formPopup.className = `${popupClass} visible success`
    //             // formPopup.className = `${popupClass} visible failed`
    //         }
    //     })
    // })

    const submitPopups = document.querySelectorAll('.submit-popup')
    submitPopups.forEach(popup => {
        const buttons = popup.querySelectorAll('.submit-popup__close, .submit-popup__exit')
        buttons.forEach(button => {
            button.addEventListener('click', function (e) {
                e.preventDefault()
                popup.classList.remove('visible')
            })
        })
    })

    //status sending, success, failed
    _wpObject.displayPopup = (form, status) => {
        const popupClass = 'submit-popup'
        const formPopup  = form.nextElementSibling
        const isPopup    = formPopup.classList.contains(popupClass)

        form.classList.add('sending')
        if (isPopup) {
            formPopup.className = `${popupClass} visible ${status}`
        }
    }
})()