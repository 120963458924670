(function () {

    const $menu = document.querySelector('.main-nav');
    const $headerRight = document.querySelector('.header__right')
    const $headerLeft = document.querySelector('.header__left')
    const $headerSocials = document.querySelector('.header .socials')
    const $headerMobile = document.querySelector('.header__mobile')
    const $headerSearch = document.querySelector('.header-search')

    const $imageTextLayout = document.querySelector('.image-text-layout')
    let $ITLHeader = null
    let $ITLContent = null

    if ($imageTextLayout) {
        $ITLHeader = $imageTextLayout.querySelector('h2')
        $ITLContent = $imageTextLayout.querySelector('.image-text-layout__content')
    }

    const $imageLayout = document.querySelector('.image-layout')
    let $ILRContent2 = null
    let $ILL = null

    if ($imageLayout) {
        $ILRContent2 = $imageLayout.querySelector('.image-layout__content--second')
        $ILL = $imageLayout.querySelector('.image-layout__left')
    }

    if (window.innerWidth < 992) {
        $headerMobile.append($menu, $headerSearch, $headerSocials)
        if ($imageTextLayout) {
            $imageTextLayout.prepend($ITLHeader)
        }
        if ($imageLayout && $ILRContent2) {
            $ILL.append($ILRContent2)
        }
    }


    window.addEventListener('resize', function () {
        if (window.innerWidth < 992) {
            if (!document.querySelector('.header__mobile .main-nav')) {
                $headerMobile.append($menu, $headerSearch, $headerSocials)
            }
            if ($imageTextLayout && !document.querySelector('.image-text-layout > h2')) {
                $imageTextLayout.prepend($ITLHeader)
            }
            if ($imageLayout && $ILRContent2 && !document.querySelector('.image-layout__left .image-layout__content--second')) {
                $ILL.append($ILRContent2)
            }
        } else {
            if (!document.querySelector('.header__left .main-nav')) {
                $headerLeft.append($menu)
                $headerRight.prepend($headerSearch, $headerSocials)
            }
            if ($imageTextLayout && !document.querySelector('.image-text-layout__content h2')) {
                $ITLContent.prepend($ITLHeader)
            }
            if ($imageLayout && $ILRContent2 && !document.querySelector('.image-layout__right .image-layout__content--second')) {
                $imageLayout.querySelector('.image-layout__right').append($ILRContent2)
            }
        }
    })
})()