(function () {

    setTimeout(() => {
        const swiper = new Swiper('.image-gallery--internal', {
            loop: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            autoplay: true,
            speed: 1000
        });


        const swiper1 = new Swiper('.life-slider .swiper', {
            loop: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.life-slider .swiper-button-next-unique',
                prevEl: '.life-slider .swiper-button-prev-unique'
            },
            pagination: {
                el: '.swiper-pagination',
            }
        });

        const eventsCardSwiper = new Swiper('.events-card__slider', {
            pagination: {
                el: '.swiper-pagination',
            },
            autoplay: true
        })

        let prevArrow = document.querySelector('.venues-slider-thumb-wrapper .swiper-button-prev-unique')
        let nextArrow = document.querySelector('.venues-slider-thumb-wrapper .swiper-button-next-unique')

        var venuesThumbs = new Swiper(".venues-slider-thumb", {
            slidesPerView: 6,
            navigation: {
                nextEl: nextArrow,
                prevEl: prevArrow,
            },
            clickable: true,
            thumbs: {
                swiper: venuesMain,
            },
        });

        var venuesMain = new Swiper(".venues-slider-main", {
            slidesPerView: 1,
            navigation: {
                nextEl: nextArrow,
                prevEl: prevArrow,
            },
            thumbs: {
                swiper: venuesThumbs,
            }
        });

        var estateSwiper = new Swiper(".estate-item__slider", {
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        var postSwiper = new Swiper(".post-slider", {
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: '.swiper-pagination',
            },
        });

        var historySwiper = new Swiper(".history-slider", {
            loop: false,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    const dates = []
                    const slides = document.querySelectorAll('.history-slider .history-card');
                    slides.forEach(slide => {
                        const date = slide.querySelector('.history-card__date').textContent;
                        dates.push(date)
                    })
                    return `<span class="${className}"><div class="circle"></div><span class="date">${dates[index]}</span></span>`;
                },
            },
            on: {
                slideChange: function (swiper) {
                    const windowWidth = window.innerWidth;
                    const paginationWrapper = swiper.el.querySelector('.pagination-wrapper');
                    const activeBullet = swiper.pagination.el.querySelector('.swiper-pagination-bullet-active');
                    paginationWrapper.scrollTo({
                        left: activeBullet.offsetLeft - (windowWidth / 2)
                    })
                }
            }
        });


        let initMenuSwiper = false;
        let menuSwiper
        let menuList = document.querySelector('.menu-list')
        let menuListSliderWrapper = document.querySelector('.menu-list > div')

        function swiperMenu() {

            if (menuList) {

                if (window.innerWidth <= 768) {
                    if (!initMenuSwiper) {
                        initMenuSwiper = true;

                        const prevArrowMenu = document.querySelector('.menu-list-wrapper .swiper-button-prev-unique')
                        const nextArrowMenu = document.querySelector('.menu-list-wrapper .swiper-button-next-unique')
                        menuList.classList.add('swiper')
                        menuListSliderWrapper.classList.add('swiper-wrapper')
                        menuSwiper = new Swiper(".menu-list", {
                            slidesPerView: 1,
                            navigation: {
                                nextEl: nextArrowMenu,
                                prevEl: prevArrowMenu,
                            },
                            pagination: {
                                el: '.swiper-pagination',
                            },
                        });
                    }
                } else if (initMenuSwiper) {
                    menuSwiper.destroy();
                    initMenuSwiper = false;
                    menuList.classList.remove('swiper')
                    menuListSliderWrapper.classList.remove('swiper-wrapper')
                }
            }
        }


        swiperMenu();
        window.addEventListener("resize", swiperMenu);

    }, 1000)
})()